import { browserPersistence2SpaStateMapper } from '../browserPersistenceMapper';
import { decodeBrowserPersistence } from '../browserPersistenceCodec';
import { PersistedSpaState } from '../types';
import { ColumnSettings } from '../../../application/redux/historytable/types';
import { decodeHistoryTablePersistence } from '../historyTablePersistenceCodec';
import { historyTablePersistence2TableSettingsMapper } from '../historyTablePersistenceMapper';

const localStorage = window.localStorage;

const STORAGE_PREFIX = 'eventHistory';
export const ROUTE_STATE = 'routeState';

const saveDomainObject = (key: string, value: Object): void => {
    try {
        localStorage.setItem(`${STORAGE_PREFIX}.${key}`, JSON.stringify(value));
    } catch (_) {
        /* Intentionally left blank */
    }
};

const loadPersistedSpaState = (key: string): PersistedSpaState | undefined => {
    const item = localStorage.getItem(`${STORAGE_PREFIX}.${key}`);
    try {
        if (item) {
            const decoded = decodeBrowserPersistence(JSON.parse(item));
            return browserPersistence2SpaStateMapper(decoded);
        }
    } catch (_) {
        /* Intentionally left blank */
    }

    return undefined;
};

const loadPersistedHistoryTableState = (key: string): ColumnSettings | undefined => {
    const item = localStorage.getItem(`${STORAGE_PREFIX}.${key}`);
    try {
        if (item) {
            const decoded = decodeHistoryTablePersistence(JSON.parse(item));
            return historyTablePersistence2TableSettingsMapper(decoded);
        }
    } catch (e) {
        console.error(e);
    }
    return undefined;
};

const loadDomainObject = (key: string): any => {
    try {
        const item = localStorage.getItem(`${STORAGE_PREFIX}.${key}`);
        if (item) {
            return JSON.parse(item);
        }
    } catch (_) {
        /* Intentionally left blank */
    }
};

const loadString = (key: string): string | null => localStorage.getItem(key);

export const storage = {
    saveDomainObject,
    loadDomainObject,
    loadPersistedSpaState,
    loadPersistedHistoryTableState,
    loadString,
};
