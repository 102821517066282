import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const name = 'positionSharing';

export interface PositionSharingState {
    showPositionSharingDialog: boolean;
}

const initialState: PositionSharingState = {
    showPositionSharingDialog: false,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setShowPositionSharingDialog: (state: PositionSharingState, action: PayloadAction<boolean>): void => {
            state.showPositionSharingDialog = action.payload;
        },
    },
});

export const positionSharingActions = actions;
export const positionSharingReducer = reducer;
