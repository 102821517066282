export enum TimeRangeType {
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    LAST_24_HOURS = 'LAST_24_HOURS',
    LAST_3_DAYS = 'LAST_3_DAYS',
    LAST_10_DAYS = 'LAST_10_DAYS',
    CUSTOM = 'CUSTOM',
}

export enum FilterHistoricEventType {
    BORDER_CROSSING = 'border-crossing',
    DRIVER_CARD = 'driver-card',
    DRIVING_STATE = 'driving-state',
    GEOFENCE = 'geofence',
    IGNITION_STATE = 'ignition-state',
    POSITION = 'position',
    PTO_STATE = 'pto-state',
}
