import { MapCredentials } from '../api/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoundingBox, Position } from '@rio-cloud/rio-uikit/components/map/utils/mapTypes';

const name = 'sidebarMap';

interface SidebarMapState {
    showSidebar: boolean;
    zoomLevel?: number;
    center?: Position;
    boundingBox?: BoundingBox;
    mapCredentials?: MapCredentials;
}

const initialState: SidebarMapState = {
    showSidebar: true,
    zoomLevel: undefined,
    center: undefined,
    boundingBox: undefined,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setShowSidebarMap: (state: SidebarMapState, action: PayloadAction<boolean>): void => {
            state.showSidebar = action.payload;
        },
        setBoundingBox: (state: SidebarMapState, action: PayloadAction<BoundingBox | undefined>): void => {
            state.boundingBox = action.payload;
        },
        setMapView: (state: SidebarMapState, action: PayloadAction<{ center: Position; zoomLevel: number }>): void => {
            state.center = action.payload.center;
            state.zoomLevel = action.payload.zoomLevel;
        },
        setMapCredentials: (state: SidebarMapState, action: PayloadAction<MapCredentials>): void => {
            state.mapCredentials = action.payload;
        },
    },
});

export const sidebarMapActions = actions;
export const sidebarMapReducer = reducer;
