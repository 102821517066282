import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { PathReporter } from 'io-ts/PathReporter';

export const decode = <T>(parsedObject: unknown, decoder: t.Decoder<unknown, T>): T => {
    const decodedObject = decoder.decode(parsedObject);
    if (isRight(decodedObject)) {
        return decodedObject.right;
    }
    throw new Error(`${PathReporter.report(decodedObject)}`);
};
