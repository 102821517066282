import { HistoricEvent } from '../../../domain/model/historicEvent.types';
import { HistoricPosition } from '../../../domain/model/historicPosition.types';
import { AssetBookings } from '../../../domain/model/productInformation.types';
import { FilterHistoricEventType, TimeRangeType } from '../../../domain/model/historyOptions.types';
import { Asset } from '../../../domain/model/asset.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const name = 'domain';

export interface DomainState {
    historicEvents: HistoricEvent[];
    historicPositions: HistoricPosition[];
    assetBookings?: AssetBookings;
    assets: Asset[];
    selectedAssetId?: string;
    timeRangeSelection: {
        fromTimestamp?: string;
        toTimestamp?: string;
        type: TimeRangeType;
    };
    includeOnlyEventTypes: FilterHistoricEventType[];
}

const initialState: DomainState = {
    historicEvents: [],
    historicPositions: [],
    assetBookings: undefined,
    assets: [],
    selectedAssetId: undefined,
    timeRangeSelection: {
        fromTimestamp: undefined,
        toTimestamp: undefined,
        type: TimeRangeType.TODAY,
    },
    includeOnlyEventTypes: [
        FilterHistoricEventType.BORDER_CROSSING,
        FilterHistoricEventType.DRIVER_CARD,
        FilterHistoricEventType.DRIVING_STATE,
        FilterHistoricEventType.GEOFENCE,
        FilterHistoricEventType.PTO_STATE,
    ],
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setHistoricEvents: (state: DomainState, action: PayloadAction<HistoricEvent[]>): void => {
            state.historicEvents = action.payload;
        },
        setHistoricPositions: (state: DomainState, action: PayloadAction<HistoricPosition[]>): void => {
            state.historicPositions = action.payload;
        },
        setAssetBookings: (state: DomainState, action: PayloadAction<AssetBookings>): void => {
            state.assetBookings = action.payload;
        },
        setAssets: (state: DomainState, action: PayloadAction<Asset[]>): void => {
            state.assets = action.payload;
        },
        setSelectedAssetId: (state: DomainState, action: PayloadAction<string | undefined>): void => {
            state.selectedAssetId = action.payload;
        },
        setTimeRangeType: (state: DomainState, action: PayloadAction<TimeRangeType>): void => {
            state.timeRangeSelection.type = action.payload;
        },
        setFromTimestamp: (state: DomainState, action: PayloadAction<string>): void => {
            state.timeRangeSelection.fromTimestamp = action.payload;
        },
        setToTimestamp: (state: DomainState, action: PayloadAction<string>): void => {
            state.timeRangeSelection.toTimestamp = action.payload;
        },
        setIncludeOnlyEventTypes: (state: DomainState, action: PayloadAction<FilterHistoricEventType[]>): void => {
            state.includeOnlyEventTypes = action.payload;
        },
        resetHistoricData: (state: DomainState): void => {
            state.historicEvents = [];
            state.historicPositions = [];
        },
    },
});

export const domainActions = actions;
export const domainReducer = reducer;
