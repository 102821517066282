import getOr from 'lodash/fp/getOr';

import { changeLocale, languageDataFetched } from './actions';
import { getSupportedLocale as defaultGetSupportedLocale } from './selectors';
import { trace } from '../setup/trace';
import { sendErrorToSentry } from '../setup/sentry';
import { getLocaleKey, importMomentLocale } from './momentLocales';
import { importDateFnsLocale } from './datefnsLocales';

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport = (imported) => getOr(imported, 'default', imported);

export const dateFnsLocaleMap = {};

export const getLanguageData = (locale) =>
    import(/* webpackChunkName: "[request]" */ `../../translations/${locale}.json`)
        .then(normalizeDynamicImport)
        .catch((error) => {
            console.error(error);
            sendErrorToSentry(error);
            return error;
        });

export const configureFetchLanguageData =
    (store, fetchLanguageData = getLanguageData, getSupportedLocale = defaultGetSupportedLocale) =>
    (locale) => {
        if (!locale) {
            console.warn('No "locale" supplied when fetching language data!');
            return Promise.reject();
        }

        store.dispatch(changeLocale(locale));
        const supportedLocale = getSupportedLocale(store.getState());

        const localeKey = getLocaleKey(supportedLocale);

        const momentPromise = importMomentLocale(localeKey)
            .then(() => {
                trace(`imported momentLocale ${localeKey}`);
            })
            .catch((error) => {
                console.error(`momentLocale ${localeKey} could not be imported`, error);
                sendErrorToSentry(error);
                return error;
            });

        const dateFnsPromise = importDateFnsLocale(localeKey)
            .then((dateFnsLocale) => {
                dateFnsLocaleMap[locale] = dateFnsLocale;
                trace(`imported dateFnsLocale ${localeKey}`);
            })
            .catch((error) => {
                console.error(`dateFnsLocale ${localeKey} could not be imported`, error);
                sendErrorToSentry(error);
                return error;
            });

        const fetchLanguageDatePromise = fetchLanguageData(supportedLocale)
            .then((languageData) => {
                trace(`Language data fetched for "${supportedLocale}"`);
                store.dispatch(languageDataFetched(supportedLocale, languageData));
            })
            .catch((error) => {
                console.error(`Language data for "${supportedLocale}" could not be fetched.`, error);
                sendErrorToSentry(error);
                return error;
            });

        return Promise.all([momentPromise, dateFnsPromise, fetchLanguageDatePromise]);
    };
