import * as t from 'io-ts';
import { decode } from '../common/mappingUtils/iotsDecoder';

const tableColumn = t.union([
    t.literal('details'),
    t.literal('timestamp'),
    t.literal('driverName'),
    t.literal('mileage'),
    t.literal('speed'),
    t.literal('fuelLevel'),
    t.literal('stateOfCharge'),
    t.literal('address'),
    t.literal('distanceToPrevious'),
    t.literal('durationSincePrevious'),
]);

const historyTablePersistenceCodec = t.partial({
    columnOrder: t.array(tableColumn),
    hiddenColumns: t.array(tableColumn),
});

export type HistoryTablePersistenceState = t.TypeOf<typeof historyTablePersistenceCodec>;
export type HistoryTableColumnPersistence = t.TypeOf<typeof tableColumn>;

export const decodeHistoryTablePersistence = (parsedObject: unknown): HistoryTablePersistenceState =>
    decode(parsedObject, historyTablePersistenceCodec);
