import { combineReducers, Reducer } from 'redux';
import { tokenHandlingReducer } from '../configuration/tokenHandling/reducer';
import { appReducer } from '../app/redux/App.reducers';
import { apiReducer } from './api/api.redux';
import { sidebarMapReducer } from './sidebarmap/sidebarMap.redux';
import { configReducer } from '../configuration/setup/configReducer';
import { langReducer } from '../configuration/lang/reducer';
import { loginReducer } from '../configuration/login/reducer';
import { dataReducer } from './data/data.redux';
import { widgetReducer } from './widget/widget.redux';
import { onboardingReducer } from '../onboarding/redux/onboarding.redux';
import { historyTableReducer } from './historytable/historyTable.redux';
import { domainReducer } from './domain/domain.redux';
import { assetTreeReducer } from './assettree/assetTree.redux';
import { positionSharingReducer } from './positionsharing/positionSharing.redux';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rootReducer = (): Reducer =>
    combineReducers({
        api: apiReducer,
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        data: dataReducer,
        login: loginReducer,
        tokenHandling: tokenHandlingReducer,
        assetTree: assetTreeReducer,
        historyTable: historyTableReducer,
        sidebarMap: sidebarMapReducer,
        positionSharing: positionSharingReducer,
        widget: widgetReducer,
        onboarding: onboardingReducer,
        domain: domainReducer,
    });

const tmp = rootReducer();
export type State = ReturnType<typeof tmp>;
