import { config } from '../../config';
import { trace } from './trace';
import { routeStorage } from '../login/storage';
import { sendErrorToSentry } from './sentry';

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');

    routeStorage.saveRoute(initialRoute);

    trace('saving initial route', initialRoute);
};

export const attemptInitialSignIn = (userManager) => {
    const isFreshRedirect = window.location.href.includes('redirected');

    return userManager
        .signinSilent()
        .then((user) => {
            const initialRoute = routeStorage.getRoute();

            trace('initialRoute lookup', initialRoute);
            if (initialRoute && isFreshRedirect) {
                trace(`Go to location "#/${initialRoute}"`);
                window.location.replace(`#/${initialRoute}`);
            }
            routeStorage.discardRoute();
            return Promise.resolve(user);
        })
        .catch((error) => {
            trace('oidc.signinSilent failed, trying page redirect...', error);

            if (config.login.preventRedirect || (sessionStorage && sessionStorage.getItem('test_access_token'))) {
                console.warn('[feature/login] redirect prevented due to config. Error was', error);
            } else if (isFreshRedirect) {
                trace('oidc.signinSilent.error', 'redirect prevented due to suspicious sign-in error', error);
                routeStorage.discardRoute();
                sendErrorToSentry(error);
            } else {
                saveCurrentRoute();
                userManager.signinRedirect();
            }

            trace('auth problem?', error);
            return Promise.reject(new Error('Need to sign in'));
        });
};
