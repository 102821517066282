import { FilterHistoricEventType } from '../../../domain/model/historyOptions.types';

export enum ApiCalls {
    INITIAL_DATA = 'initialData',
    ASSET_HISTORY = 'assetHistory',
}

export enum ErrorCauses {
    ASSET_HISTORY_EXCEEDED_MAX_REQUEST_INTERVAL = 'assetHistoryExceededMaxRequestInterval',
    ASSET_HISTORY_FETCH = 'assetHistoryFetch',
    INITIAL_DATA_FETCH = 'initialDataFetch',
    ACCESS_DENIED_FETCH = 'accessDeniedFetch',
}

export interface HistoricPositionsRequestParameters {
    assetId: string;
    fromTimestamp: string;
    toTimestamp: string;
    locale: string;
}

export interface HistoricEventsRequestParameters extends HistoricPositionsRequestParameters {
    includeOnlyEventTypes: FilterHistoricEventType[];
}

export interface MapCredentials {
    id: string;
    provider: string;
    apikey: string;
}
