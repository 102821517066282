import { eventTableDefinition, HistoryTableColumns, TableColumn } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterHistoricEventType, TimeRangeType } from '../../../domain/model/historyOptions.types';
import { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import SortDirection, { SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';

const name = 'historyTable';

export interface AssetHistoryTableState {
    displayEventFilterDialog: boolean;
    selectedAssetId?: string;
    selectedEventId?: string;
    searchText: string;
    displaySearchText: string;
    includeEvents: FilterHistoricEventType[];
    timeRangeType: TimeRangeType;
    startDate?: string;
    endDate?: string;
    sortByColumn: TableColumn;
    sortDir: SortDirectionType;
    chunks: number;
    tableColumns: HistoryTableColumns;
    showTableSettings: boolean;
    tableViewMode: TableViewTogglesViewType;
}

const initialState: AssetHistoryTableState = {
    searchText: '',
    displaySearchText: '',
    selectedAssetId: undefined,
    selectedEventId: undefined,
    displayEventFilterDialog: false,
    timeRangeType: TimeRangeType.TODAY,
    startDate: undefined,
    endDate: undefined,
    includeEvents: [
        FilterHistoricEventType.BORDER_CROSSING,
        FilterHistoricEventType.DRIVER_CARD,
        FilterHistoricEventType.DRIVING_STATE,
        FilterHistoricEventType.GEOFENCE,
        FilterHistoricEventType.PTO_STATE,
    ],
    showTableSettings: false,
    sortByColumn: TableColumn.TIMESTAMP,
    sortDir: SortDirection.ASCENDING,
    tableViewMode: 'TABLE',
    chunks: 1,
    tableColumns: {
        columnOrder: eventTableDefinition.defaultColumnOrder,
        hiddenColumns: eventTableDefinition.defaultHiddenColumns,
    },
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setTableSorting: (
            state: AssetHistoryTableState,
            action: PayloadAction<{ sortByColumn: TableColumn; sortDir: SortDirectionType }>
        ): void => {
            state.sortDir = action.payload.sortDir;
            state.sortByColumn = action.payload.sortByColumn;
        },
        setTableViewMode: (state: AssetHistoryTableState, action: PayloadAction<TableViewTogglesViewType>): void => {
            state.tableViewMode = action.payload;
        },
        increaseTableChunks: (state: AssetHistoryTableState): void => {
            state.chunks = state.chunks + 1;
        },
        resetTableChunks: (state: AssetHistoryTableState): void => {
            state.chunks = 1;
        },
        toggleTableSettingDialog: (state: AssetHistoryTableState): void => {
            state.showTableSettings = !state.showTableSettings;
        },
        setTableColumnSettings: (state: AssetHistoryTableState, action: PayloadAction<HistoryTableColumns>): void => {
            state.tableColumns = action.payload;
        },
        toggleEventFilterDialog: (state: AssetHistoryTableState): void => {
            state.displayEventFilterDialog = !state.displayEventFilterDialog;
        },
        updateSearchText: (state: AssetHistoryTableState, action: PayloadAction<string>): void => {
            state.searchText = action.payload;
        },
        updateDisplaySearchText: (state: AssetHistoryTableState, action: PayloadAction<string>): void => {
            state.displaySearchText = action.payload;
        },
        selectEvent: (state: AssetHistoryTableState, action: PayloadAction<string | undefined>): void => {
            if (action.payload === undefined || state.selectedEventId === action.payload) {
                state.selectedEventId = undefined;
            } else {
                state.selectedEventId = action.payload;
            }
        },
    },
});

export const historyTableActions = actions;
export const historyTableReducer = reducer;
