/* eslint-disable no-redeclare */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { FilterHistoricEventType, TimeRangeType } from '../../../domain/model/historyOptions.types';
import { TableColumn } from '../../../application/redux/historytable/types';
import { isAfter, isValid, parseISO, startOfDay, toDate } from 'date-fns';
import SortDirection, { SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';

export const mapTimeRangeType = (
    timeRangeType: 'TODAY' | 'YESTERDAY' | 'LAST_24_HOURS' | 'LAST_3_DAYS' | 'LAST_10_DAYS' | 'CUSTOM' | undefined,
    startDate?: string,
    endDate?: string
): TimeRangeType => {
    const mappedTimeRangeType = mapTimeRangePreselection(timeRangeType);

    if (mappedTimeRangeType) {
        return mappedTimeRangeType;
    } else {
        return startDate && endDate ? TimeRangeType.CUSTOM : TimeRangeType.TODAY;
    }
};

const mapTimeRangePreselection = (
    input: 'TODAY' | 'YESTERDAY' | 'LAST_24_HOURS' | 'LAST_3_DAYS' | 'LAST_10_DAYS' | 'CUSTOM' | undefined
): TimeRangeType | undefined => {
    switch (input) {
        case 'TODAY':
            return TimeRangeType.TODAY;
        case 'YESTERDAY':
            return TimeRangeType.YESTERDAY;
        case 'LAST_24_HOURS':
            return TimeRangeType.LAST_24_HOURS;
        case 'LAST_3_DAYS':
            return TimeRangeType.LAST_3_DAYS;
        case 'LAST_10_DAYS':
            return TimeRangeType.LAST_10_DAYS;
        case 'CUSTOM':
            return TimeRangeType.CUSTOM;
        default:
            return undefined;
    }
};

export const mapSortDir = (sortDir: undefined | 'asc' | 'desc'): SortDirectionType | undefined => {
    switch (sortDir) {
        case 'asc':
            return SortDirection.ASCENDING;
        case 'desc':
            return SortDirection.DESCENDING;
        default:
            return undefined;
    }
};

export const mapSortByColumn = (
    sortBy:
        | 'details'
        | 'timestamp'
        | 'driverName'
        | 'mileage'
        | 'speed'
        | 'fuelLevel'
        | 'address'
        | 'distanceToPrevious'
        | 'durationSincePrevious'
        | 'stateOfCharge'
        | undefined
): TableColumn | undefined => {
    switch (sortBy) {
        case 'details':
            return TableColumn.DETAILS;
        case 'timestamp':
            return TableColumn.TIMESTAMP;
        case 'driverName':
            return TableColumn.DRIVER_NAME;
        case 'mileage':
            return TableColumn.MILEAGE;
        case 'speed':
            return TableColumn.SPEED;
        case 'fuelLevel':
            return TableColumn.FUEL_LEVEL;
        case 'stateOfCharge':
            return TableColumn.STATE_OF_CHARGE;
        case 'address':
            return TableColumn.ADDRESS;
        case 'distanceToPrevious':
            return TableColumn.DISTANCE_TO_PREVIOUS;
        case 'durationSincePrevious':
            return TableColumn.DURATION_SINCE_PREVIOUS;
        default:
            return undefined;
    }
};

export const mapIncludeEvents = (
    includeEvents:
        | 'first-and-last-position'
        | 'border-crossing'
        | 'driver-card'
        | 'driving-state'
        | 'geofence'
        | 'ignition-state'
        | 'position'
        | 'pto-state'
        | undefined
): FilterHistoricEventType | undefined => {
    switch (includeEvents) {
        case 'border-crossing':
            return FilterHistoricEventType.BORDER_CROSSING;
        case 'driver-card':
            return FilterHistoricEventType.DRIVER_CARD;
        case 'driving-state':
            return FilterHistoricEventType.DRIVING_STATE;
        case 'geofence':
            return FilterHistoricEventType.GEOFENCE;
        case 'ignition-state':
            return FilterHistoricEventType.IGNITION_STATE;
        case 'position':
            return FilterHistoricEventType.POSITION;
        case 'pto-state':
            return FilterHistoricEventType.PTO_STATE;
        default:
            return undefined;
    }
};

export function mapEndDate(endDate: undefined): undefined;
export function mapEndDate(endDate: string): string;
export function mapEndDate(endDate?: string): string | undefined;
export function mapEndDate(endDate?: string): string | undefined {
    if (!endDate) {
        return undefined;
    }
    const parsedEndDate = parseISO(endDate);
    const now = toDate(Date.now());

    if (!isValid(parsedEndDate)) {
        return undefined;
    }

    if (isAfter(parsedEndDate, now)) {
        return now.toISOString();
    }

    return parsedEndDate.toISOString();
}

export const mapStartDate = (startDate?: string, endDate?: string): string | undefined => {
    if (!startDate) {
        return undefined;
    }
    const parsedStartDate = parseISO(startDate);
    if (!isValid(parsedStartDate)) {
        return undefined;
    }
    const now = toDate(Date.now());
    if (isAfter(parsedStartDate, now)) {
        return startOfDay(now).toISOString();
    }

    if (endDate) {
        const parsedEndDate = parseISO(mapEndDate(endDate));
        if (isAfter(parsedStartDate, parsedEndDate)) {
            return startOfDay(parsedEndDate).toISOString();
        }
    }

    return parsedStartDate.toISOString();
};
