import './polyfills';

import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router';

import { handleLoginRedirect, main, store } from './application/configuration';
import { ErrorBoundary } from './application/app/ErrorBoundary';
import './application/configuration/setup/datadog';
import { LoadingIndicator } from './features/common/displaycomponents/LoadingIndicator';
import { config } from './application/config';
import { createRoot } from 'react-dom/client';
import { ConfigCatProvider, createConsoleLogger, DataGovernance, LogLevel, PollingMode } from 'configcat-react';

const AppContainer = lazy(() => import(/* webpackChunkName: "SPA" */ './application/app/SPA.container'));
const WidgetContainer = lazy(() => import(/* webpackChunkName: "Widget" */ './application/app/Widget.container'));

const renderApplication = (): void => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorBoundary>
            <ConfigCatProvider
                sdkKey={config.configCat.apiKey}
                pollingMode={PollingMode.AutoPoll}
                options={{
                    pollIntervalSeconds: 2 * 86400, // 2 days
                    dataGovernance: DataGovernance.EuOnly,
                    logger: createConsoleLogger(LogLevel.Off),
                }}
            >
                <Provider store={store}>
                    <Suspense fallback={<LoadingIndicator />}>
                        <HashRouter>
                            <Routes>
                                <Route path="/*" element={<AppContainer />} />
                                <Route path="/widget" element={<WidgetContainer />} />
                            </Routes>
                        </HashRouter>
                    </Suspense>
                </Provider>
            </ConfigCatProvider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
