import { FilterHistoricEventType, TimeRangeType } from '../../domain/model/historyOptions.types';
import { TableColumn } from '../../application/redux/historytable/types';
import { PersistedSpaState } from './types';
import {
    mapEndDate,
    mapIncludeEvents,
    mapSortByColumn,
    mapSortDir,
    mapStartDate,
    mapTimeRangeType,
} from '../common/mapper/browserPersistenceStateMapper';
import { BrowserPersistenceState } from './browserPersistenceCodec';

export const browserPersistence2SpaStateMapper = (input: BrowserPersistenceState): PersistedSpaState => {
    const timeRangeType = mapTimeRangeType(input.timeRangeType, input.fromTimestamp, input.toTimestamp);

    return {
        selectedAssetIds: input.selectedAssetIds?.filter((it) => it !== 'null'),
        selectedGroupIds: input.selectedGroupIds,
        fromTimestamp:
            timeRangeType === TimeRangeType.CUSTOM ? mapStartDate(input.fromTimestamp, input.toTimestamp) : undefined,
        toTimestamp: timeRangeType === TimeRangeType.CUSTOM ? mapEndDate(input.toTimestamp) : undefined,
        timeRangeType,
        includeEvents: input.includeEvents
            ?.map((it) => mapIncludeEvents(it))
            .filter((it) => it !== undefined) as FilterHistoricEventType[],
        hiddenTableColumns: input.hiddenTableColumns
            ?.map((it) => mapSortByColumn(it))
            .filter((it) => it !== undefined) as TableColumn[],
        search: input.search,
        sortDir: mapSortDir(input.sortDir),
        sortByColumn: mapSortByColumn(input.sortByColumn),
        tableViewMode: input.tableViewMode,
    };
};
