import cloneDeep from 'lodash/fp/cloneDeep';
import { config } from '../../config';

const initial = cloneDeep(config);

export const configReducer = (state = initial, action = {}) => {
    switch (action.type) {
        default:
            return state;
    }
};
