import { ColumnSettings, TableColumn } from '../../application/redux/historytable/types';
import { HistoryTableColumnPersistence, HistoryTablePersistenceState } from './historyTablePersistenceCodec';

const mapTableColumn = (column: HistoryTableColumnPersistence): TableColumn => {
    switch (column) {
        case 'details':
            return TableColumn.DETAILS;
        case 'timestamp':
            return TableColumn.TIMESTAMP;
        case 'driverName':
            return TableColumn.DRIVER_NAME;
        case 'mileage':
            return TableColumn.MILEAGE;
        case 'speed':
            return TableColumn.SPEED;
        case 'fuelLevel':
            return TableColumn.FUEL_LEVEL;
        case 'stateOfCharge':
            return TableColumn.STATE_OF_CHARGE;
        case 'address':
            return TableColumn.ADDRESS;
        case 'distanceToPrevious':
            return TableColumn.DISTANCE_TO_PREVIOUS;
        case 'durationSincePrevious':
            return TableColumn.DURATION_SINCE_PREVIOUS;
    }
};

export const historyTablePersistence2TableSettingsMapper = (
    input: HistoryTablePersistenceState
): ColumnSettings | undefined => {
    if (!input.hiddenColumns && !input.columnOrder) {
        return undefined;
    }
    if (!input.hiddenColumns || !input.columnOrder) {
        throw new Error(`Incomplete data found in local storage: ${JSON.stringify(input)} `);
    }
    return {
        columnOrder: input.columnOrder.map((column) => mapTableColumn(column)),
        hiddenColumns: input.hiddenColumns.map((column) => mapTableColumn(column)),
    };
};
