const asBool = (value: string | undefined): boolean => value === 'true';

interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockUser?: string;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    sentryToken: string;
    configCat: {
        apiKey: string;
    };
    hereBaseUrl: string;
}

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '5de7b178-0b16-4c89-b920-97805866074a',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'location.read',
            'asset-administration.read',
            'tag.read',
            'truck-state.read',
            'geofence.read',
            'bordercrossing.read',
            'here-map.proxy.read',
            'drivers.read',
        ],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockUser: import.meta.env.VITE_LOGIN_MOCK_USER,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: 'https://865cf7e212194b379b88ebde9f4f12af@sentry.io/1841109',
    configCat: {
        apiKey: 'configcat-sdk-1/nkDbCJpbx0KNCeDBl6s5TA/6uSPMUJq_Eyr_2V3Yl8Vmw',
    },
    hereBaseUrl: 'https://js.api.here.com/v3/3.1/',
};
