import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetItem, GroupedAssets } from './types';
import { AssetBookings } from '../../../domain/model/productInformation.types';
import { TreeGroup } from '@rio-cloud/rio-uikit/Tree';

const name = 'data';

export interface DataState {
    trucks: AssetItem[];
    truckGroups: TreeGroup[];
    assetBookings?: AssetBookings;
    lastAssetHistoryFetchTime?: string;
}

const initialState: DataState = {
    trucks: [],
    truckGroups: [],
    lastAssetHistoryFetchTime: undefined,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setFetchedGroupedAssets: (state: DataState, action: PayloadAction<GroupedAssets>): void => {
            state.trucks = action.payload.trucks;
            state.truckGroups = action.payload.truckGroups;
        },
        setLastAssetHistoryFetchTime: (state: DataState, action: PayloadAction<string>): void => {
            state.lastAssetHistoryFetchTime = action.payload;
        },
    },
});

export const dataActions = actions;
export const dataReducer = reducer;
