import { ApiCalls, ErrorCauses } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const name = 'api';

interface ApiLoadingState {
    loading: { [key in ApiCalls]: boolean };
    error: { [key in ErrorCauses]: boolean };
}

const initialState: ApiLoadingState = {
    loading: {
        [ApiCalls.INITIAL_DATA]: false,
        [ApiCalls.ASSET_HISTORY]: false,
    },
    error: {
        [ErrorCauses.ASSET_HISTORY_EXCEEDED_MAX_REQUEST_INTERVAL]: false,
        [ErrorCauses.INITIAL_DATA_FETCH]: false,
        [ErrorCauses.ASSET_HISTORY_FETCH]: false,
        [ErrorCauses.ACCESS_DENIED_FETCH]: false,
    },
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setLoadingState: (state: ApiLoadingState, action: PayloadAction<{ type: ApiCalls; value: boolean }>): void => {
            state.loading[action.payload.type] = action.payload.value;
        },
        setError: (state: ApiLoadingState, action: PayloadAction<{ type: ErrorCauses; value: boolean }>): void => {
            state.error[action.payload.type] = action.payload.value;
        },
    },
});

export const apiActions = actions;
export const apiReducer = reducer;
