import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CATEGORY_TRUCK } from './types';

const name = 'assetTree';

export interface AssetTreeState {
    displayedAssetCategory: string;
    isTreeOpen: boolean;

    selectedTruckIds: string[];
    selectedTruckGroupIds: string[];
    expandedTruckGroupIds: string[];
}

const initialState: AssetTreeState = {
    displayedAssetCategory: CATEGORY_TRUCK,
    isTreeOpen: true,
    selectedTruckIds: [],
    selectedTruckGroupIds: [],
    expandedTruckGroupIds: [],
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        toggleTreeOpen: (state: AssetTreeState): void => {
            state.isTreeOpen = !state.isTreeOpen;
        },
        setDisplayedAssetCategory: (state: AssetTreeState, action: PayloadAction<string>): void => {
            state.displayedAssetCategory = action.payload;
        },
        setSelectTruck: (state: AssetTreeState, action: PayloadAction<string[]>): void => {
            state.selectedTruckIds = action.payload;
        },
        setSelectTruckGroup: (state: AssetTreeState, action: PayloadAction<string[]>): void => {
            state.selectedTruckGroupIds = action.payload;
        },
        setExpandTruckGroup: (state: AssetTreeState, action: PayloadAction<string[]>): void => {
            state.expandedTruckGroupIds = action.payload;
        },
    },
});

export const assetTreeActions = actions;
export const assetTreeReducer = reducer;
