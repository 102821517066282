import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterHistoricEventType, TimeRangeType } from '../../../domain/model/historyOptions.types';

export enum EventOrdering {
    OLDEST_FIRST = 'OLDEST_FIRST',
    NEWEST_FIRST = 'NEWEST_FIRST',
}

interface WidgetState {
    widgetId?: string;
    visibleWidgets: string[];
    selectedEventId?: string;
    isScrollEventListItemIntoView: boolean;
    isMapContextHistory: boolean;
    shouldDisplayNow: boolean;
    selectedAssetId?: string;
    startDate?: string;
    endDate?: string;
    eventOrdering: EventOrdering;
    timeRangeType: TimeRangeType;
    includeOnlyEventTypes: FilterHistoricEventType[];
    showAllMarkersOnMap: boolean;
}

const initialState: WidgetState = {
    widgetId: undefined,
    visibleWidgets: [],
    selectedEventId: undefined,
    isScrollEventListItemIntoView: false,
    isMapContextHistory: false,
    shouldDisplayNow: true,
    selectedAssetId: undefined,
    startDate: undefined,
    endDate: undefined,
    eventOrdering: EventOrdering.OLDEST_FIRST,
    timeRangeType: TimeRangeType.TODAY,
    includeOnlyEventTypes: [
        FilterHistoricEventType.BORDER_CROSSING,
        FilterHistoricEventType.DRIVER_CARD,
        FilterHistoricEventType.DRIVING_STATE,
        FilterHistoricEventType.GEOFENCE,
        FilterHistoricEventType.PTO_STATE,
    ],
    showAllMarkersOnMap: true,
};

const { reducer, actions } = createSlice({
    name: 'widget',
    initialState,
    reducers: {
        setWidgetId: (state: WidgetState, action: PayloadAction<string>): void => {
            state.widgetId = action.payload;
        },
        setVisibleWidgets: (state: WidgetState, action: PayloadAction<string[]>): void => {
            state.visibleWidgets = action.payload;
        },
        setSelectedEventId: (state: WidgetState, action: PayloadAction<string | undefined>): void => {
            state.selectedEventId = action.payload;
        },
        setScrollIntoView: (state: WidgetState, action: PayloadAction<boolean>): void => {
            state.isScrollEventListItemIntoView = action.payload;
        },
        setMapContextToHistory: (state: WidgetState, action: PayloadAction<boolean>): void => {
            state.isMapContextHistory = action.payload;
        },
        setShouldDisplayNow: (state: WidgetState, action: PayloadAction<boolean>): void => {
            state.shouldDisplayNow = action.payload;
        },
        setSelectedAssetId: (state: WidgetState, action: PayloadAction<string | undefined>): void => {
            state.selectedAssetId = action.payload;
        },
        setStartDate: (state: WidgetState, action: PayloadAction<string>): void => {
            state.startDate = action.payload;
        },
        setEndDate: (state: WidgetState, action: PayloadAction<string>): void => {
            state.endDate = action.payload;
        },
        setEventOrdering: (state: WidgetState, action: PayloadAction<EventOrdering>): void => {
            state.eventOrdering = action.payload;
        },
        setTimeRangeType: (state: WidgetState, action: PayloadAction<TimeRangeType>): void => {
            state.timeRangeType = action.payload;
        },
        setShowAllMarkersOnMap: (state: WidgetState, action: PayloadAction<boolean>): void => {
            state.showAllMarkersOnMap = action.payload;
        },
        toggleShowAllMarkersOnMap: (state: WidgetState): void => {
            state.showAllMarkersOnMap = !state.showAllMarkersOnMap;
        },
    },
});

export const widgetActions = actions;
export const widgetReducer = reducer;
