export const HIDE_SESSION_EXPIRED_DIALOG = 'app/HIDE_SESSION_EXPIRED_DIALOG';
export interface AppState {
    sessionExpiredAcknowledged: boolean;
}

interface HideSessionExpiredAction {
    type: typeof HIDE_SESSION_EXPIRED_DIALOG;
}

export type AppActions = HideSessionExpiredAction;
