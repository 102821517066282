/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

// dynamic imports locations must be static strings as they are analysed by vite during bundling. Otherwise, they will not work.
const momentLocaleImports = {
    bg: () => import('moment/dist/locale/bg'),
    cs: () => import('moment/dist/locale/cs'),
    da: () => import('moment/dist/locale/da'),
    de: () => import('moment/dist/locale/de'),
    'en-gb': () => import('moment/dist/locale/en-gb'),
    el: () => import('moment/dist/locale/el'),
    es: () => import('moment/dist/locale/es'),
    et: () => import('moment/dist/locale/et'),
    fi: () => import('moment/dist/locale/fi'),
    fr: () => import('moment/dist/locale/fr'),
    hr: () => import('moment/dist/locale/hr'),
    hu: () => import('moment/dist/locale/hu'),
    it: () => import('moment/dist/locale/it'),
    ko: () => import('moment/dist/locale/ko'),
    lt: () => import('moment/dist/locale/lt'),
    lv: () => import('moment/dist/locale/lv'),
    nb: () => import('moment/dist/locale/nb'),
    nl: () => import('moment/dist/locale/nl'),
    pl: () => import('moment/dist/locale/pl'),
    pt: () => import('moment/dist/locale/pt'),
    'pt-br': () => import('moment/dist/locale/pt-br'),
    ro: () => import('moment/dist/locale/ro'),
    sk: () => import('moment/dist/locale/sk'),
    sl: () => import('moment/dist/locale/sl'),
    sv: () => import('moment/dist/locale/sv'),
};

export const getLocaleKey = (locale: string) => {
    if (locale.toLowerCase() === 'pt-br') {
        return 'pt-br';
    } else {
        const countryCode = locale.substring(0, 2);
        if (countryCode === 'en') {
            return 'en-gb';
        } else {
            return countryCode;
        }
    }
};

export const importMomentLocale = async (locale: string) => {
    if (momentLocaleImports[locale]) {
        await momentLocaleImports[locale]();
    } else {
        throw new Error(`momentLocale ${locale} not supported`);
    }
};
