import { Locale } from 'date-fns/locale';

// dynamic imports locations must be static strings as they are analysed by vite during bundling. Otherwise, they will not work.
const dateFnsLocaleImport: Record<string, () => Promise<Locale>> = {
    bg: () => import('date-fns/locale/bg').then((module) => module.bg),
    cs: () => import('date-fns/locale/cs').then((module) => module.cs),
    da: () => import('date-fns/locale/da').then((module) => module.da),
    de: () => import('date-fns/locale/de').then((module) => module.de),
    'en-gb': () => import('date-fns/locale/en-GB').then((module) => module.enGB),
    el: () => import('date-fns/locale/el').then((module) => module.el),
    es: () => import('date-fns/locale/es').then((module) => module.es),
    et: () => import('date-fns/locale/et').then((module) => module.et),
    fi: () => import('date-fns/locale/fi').then((module) => module.fi),
    fr: () => import('date-fns/locale/fr').then((module) => module.fr),
    hr: () => import('date-fns/locale/hr').then((module) => module.hr),
    hu: () => import('date-fns/locale/hu').then((module) => module.hu),
    it: () => import('date-fns/locale/it').then((module) => module.it),
    ko: () => import('date-fns/locale/ko').then((module) => module.ko),
    lt: () => import('date-fns/locale/lt').then((module) => module.lt),
    lv: () => import('date-fns/locale/lv').then((module) => module.lv),
    nb: () => import('date-fns/locale/nb').then((module) => module.nb),
    nl: () => import('date-fns/locale/nl').then((module) => module.nl),
    pl: () => import('date-fns/locale/pl').then((module) => module.pl),
    pt: () => import('date-fns/locale/pt').then((module) => module.pt),
    'pt-br': () => import('date-fns/locale/pt-BR').then((module) => module.ptBR),
    ro: () => import('date-fns/locale/ro').then((module) => module.ro),
    sk: () => import('date-fns/locale/sk').then((module) => module.sk),
    sl: () => import('date-fns/locale/sl').then((module) => module.sl),
    sv: () => import('date-fns/locale/sv').then((module) => module.sv),
};

export const importDateFnsLocale = async (locale: string) => {
    if (dateFnsLocaleImport[locale]) {
        return dateFnsLocaleImport[locale]();
    } else {
        throw new Error(`dateFnsLocale ${locale} could not be imported`);
    }
};
